<template>
  <div class="radio-button-question">
    <div class="question-text">{{ question.text }}</div>

    <div class="radio">
      <div v-for="(option, index) in question.options" :key="index" class="radio-option">
        <div class="radio-row">
          <input class="radio-button"
                 type="radio"
                 :id="option.value"
                 :value="option.value"
                 v-model="selected"
                 v-on:change="setAnswer(selected)"/>
          <label class="answer-text" :for="option.value">{{ option.label }}</label>
        </div>
        <input v-if="hasTextInputDetails && isSelected(option)"
               class="text-input" placeholder="עוד כמה מילים"
               v-on:input="setTextAnswer($event.target.value)"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RadioButtonQuestion',
  data() {
    return {
      selected: null,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    setTextAnswer: {
      type: Function,
      required: false,
    },
    setAnswer: {
      type: Function,
      required: true,
    },
  },
  computed: {
    hasTextInputDetails() {
      return !!this.setTextAnswer;
    },
  },
  methods: {
    isSelected(option) {
      return option.value === this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
$radio-button-width: 20px;
$radio-text-padding: 10px;

.radio-button-question {
  font-family: SimplerPro;

  .question-text {
    font-weight: bold;
    font-size: 22px;
    color: $riseup_blue;
    padding-bottom: 30px;
    line-height: 26px;
  }

  .radio {
    margin: 10px 0 30px;
    font-size: 16px;

    .radio-option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;

      .radio-row {
        display: flex;
        align-items: flex-start;

        .radio-button {
          width: $radio_button_width;
          height: 20px;
          flex-shrink: 0;
        }
        .answer-text {
          color: $riseup_black;
          line-height: 1.5;
          font-size: 16px;
          padding-right: $radio-text-padding;
        }
        input:checked + label {
          font-weight: bold;
        }
      }
    }

    .text-input {
      width: 80%;
      margin: 14px $radio-button-width + $radio-text-padding 14px 0;
      padding-bottom: 4px;
      text-align: right;
      outline: 0;
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid $riseup_gray_2;
      font-size: 16px;
      font-family: SimplerPro;
      &:focus {
        border-bottom: 2px solid $riseup_blue;
      }
    }
  }
}
</style>
