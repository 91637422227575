<template>
  <div class="lead-qualification-flow">
    <riseup-header/>
    <radio-button-survey v-if="componentType === componentTypes.QUESTION"
                         :totalQuestions="totalQuestions"
                         v-bind="surveyQuestion"
                         @new-survey-question="newQuestion" @next-clicked="nextClicked"/>
    <value-prop-cashflow-goal v-else-if="isRiseupGoalValueProp" :next="nextPage"/>
    <value-prop v-else :component-name="componentName" :next="nextPage"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import RadioButtonSurvey from '../../../../../base-components/survey/RadioButtonSurvey.vue';
import ValueProp from './value-props/ValueProp.vue';
import ValuePropCashflowGoal from './value-props/ValuePropCashflowGoal.vue';
import valueProps from './consts/value-props';
import RiseupHeader from '../../../../../base-components/app-header/RiseupHeader.vue';
import Segment from '../../../../../Segment';
import questions from './consts/questions';

export default {
  name: 'LeadQualificationSurvey',
  components: {
    RiseupHeader,
    RadioButtonSurvey,
    ValueProp,
    ValuePropCashflowGoal,
  },
  props: {
    componentName: {
      type: String,
      required: true,
    },
    componentType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.gotoPage({ componentName: this.componentName, componentType: this.componentType });
  },
  computed: {
    ...mapState('leadQualification', ['componentTypes']),
    ...mapGetters('leadQualification', ['currentQuestion', 'questionIndex', 'totalQuestions']),
    surveyQuestion() {
      return { ...this.currentQuestion, index: this.questionIndex };
    },
    isRiseupGoalValueProp() {
      const isCashflowGoal = this.componentName === valueProps.VALUE_PROP_NAMES.CASHFLOW_GOAL;
      return this.componentType === this.componentTypes.VALUE_PROP && isCashflowGoal;
    },
  },
  methods: {
    ...mapActions('leadQualification', ['gotoPage', 'nextPage']),
    newQuestion() {
      const { name: questionName } = this.currentQuestion;
      Segment.trackUserInteraction(`LQQuestion${questionName}Entered`, { questionIndex: this.questionIndex, questionName });
      Segment.trackUserGot(`Question_${questionName}_Entered_Marketing`);
    },
    nextClicked({ answer }) {
      const { name: questionName } = this.currentQuestion;
      Segment.trackUserInteraction(`LQQuestion${questionName}AnswerClicked`, {
        questionIndex: this.questionIndex,
        questionName,
        answer,
        ...(questionName === questions.QUESTION_NAMES.AVERAGE_INCOME ? { bar: 14000 } : {}),
      });
      Segment.trackUserInteractionToPixel(`LQ${answer}`);
      const { marketingCode } = this.currentQuestion.options.find(option => option.value === answer);
      Segment.trackUserInteraction(`Answer_${questionName}_${marketingCode}_Marketing`);
      Segment.trackUserInteraction(`Question_${questionName}_NextClicked_Marketing`);
      this.nextPage({ questionName, answer });
    },
  },
  watch: {
    componentName(componentName) {
      this.gotoPage({ componentName, componentType: this.componentType });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.lead-qualification-flow {
  @include white-page-frame;
  height: 100%;
}
</style>
