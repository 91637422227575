<template>
  <div class="progress">{{ questionProgressText }}</div>
</template>

<script>
export default {
  name: 'SurveyProgressText',
  props: {
    questionIndex: {
      type: Number,
      required: true,
    },
    numOfQuestions: {
      type: Number,
      required: true,
    },
  },
  computed: {
    questionProgressText() {
      if (this.questionIndex + 1 === this.numOfQuestions) {
        return 'והשאלה האחרונה';
      }
      return `${this.questionIndex + 1} מתוך ${this.numOfQuestions}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  .progress {
    margin-bottom: 5px;
    color: $riseup_blue;
    font-size: 16px;
  }
</style>
