<template>
  <div class="radio-button-survey">
    <div class="page-content">
      <div>
        <survey-progress-text v-if="totalQuestions > 1" :question-index="index" :num-of-questions="totalQuestions"/>
        <radio-button-question :key="name"
                               :question="{ text, options }"
                               :set-answer="setAnswer"
                               :set-text-answer="useTextAnswer ? setTextAnswer : null"/>
      </div>
      <div class="button-container">
        <riseup-button class="next-question" :title="'אפשר להמשיך'" :action="saveAnswer" :size="'small'" :disabled="!answer"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import RadioButtonQuestion from './RadioButtonQuestion.vue';
import SurveyProgressText from './SurveyProgressText.vue';

export default {
  name: 'RadioButtonSurvey',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    RadioButtonQuestion,
    SurveyProgressText,
  },
  created() {
    this.$emit('new-survey-question', { questionName: this.name });
  },
  data() {
    return {
      answer: null,
      textAnswer: '',
    };
  },
  props: {
    name: { type: String, required: true },
    index: { type: Number, required: true },
    text: { type: String, required: true },
    options: { type: Array, required: true },
    totalQuestions: { type: Number, required: true },
    useTextAnswer: { type: Boolean, required: false, default: false },
  },
  watch: {
    name(newVal) {
      if (newVal) {
        this.$emit('new-survey-question', { questionName: this.name });
      }
    },
  },
  methods: {
    saveAnswer() {
      this.$emit('next-clicked', { answer: this.answer, textAnswer: this.textAnswer });
      this.answer = null;
      this.textAnswer = null;
    },
    setAnswer(newAnswer) {
      this.answer = newAnswer;
    },
    setTextAnswer(newAnswer) {
      this.textAnswer = newAnswer;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .radio-button-survey {
    @include white-page-frame;
    height: 100%;

    .page-content {
      @include responsive-padding;
      font-family: SimplerPro;
      margin-top: 40px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .button-container {
        @include responsive-padding-top-bottom;

        .next-question {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }

</style>
