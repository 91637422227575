<template>
  <div class="value-prop">
    <div class="image-container">
      <div class="player-wrapper">
        <lottie-player class="player" :class="[{ 'relative-player': valueProp.imageName }, valueProp.backgroundColor]"
                       :src="`/animations/${valueProp.animation}`" loop
                       :autoplay="true"
                       :preserveAspectRatio="'xMaxYMin'"
                       :key="valueProp.name"/>
      </div>
      <img class="image" v-if="valueProp.imageName" :src="require(`@/assets/wine-and-dine/${valueProp.imageName}`)">
    </div>
    <div class="page-content">
      <div class="description">
        <div class="title">{{ valueProp.title }}</div>
        <div class="subtitle">{{ valueProp.subtitle }}</div>
      </div>
      <div class="button-container">
        <riseup-button class="next-question" :title="'הבנתי, מצוין'" :action="nextClicked" :size="'small'" />
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import valueProps from '../consts/value-props';
import Segment from '../../../../../../Segment';

export default {
  name: 'ValueProp',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot(`ValueProp-${this.componentName}-Entered`, { valuePropIndex: this.valuePropIndex, valuePropName: this.componentName });
    Segment.trackUserGot(`ValueProp_${this.componentName}_Entered_Marketing`);
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
    componentName: {
      type: String,
      required: true,
    },
  },
  computed: {
    valueProp() {
      return _.find(valueProps.VALUE_PROPS, { name: this.componentName });
    },
    valuePropIndex() {
      return _.findIndex(valueProps.VALUE_PROPS, { name: this.componentName });
    },
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction(`ValueProp_${this.componentName}_NextClicked_Marketing`);
      this.next({});
    },
  },
  watch: {
    componentName(newVal) {
      if (newVal) {
        Segment.trackUserGot(`ValueProp-${this.componentName}-Entered`, { valuePropIndex: this.valuePropIndex, valuePropName: this.componentName });
        Segment.trackUserGot(`ValueProp_${this.componentName}_Entered_Marketing`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';

  .value-prop {
    @include white-page-frame;
    height: 100%;

    .image-container {
      position: relative;
      width: 100%;

      .player-wrapper {
        top: 0;
        left: 0;

        .player {
          &.relative-player {
            position: absolute;
            top: 0;
            left: 0;
          }
          &.light-blue {
            background-color: $riseup_light_blue;
          }
          &.green {
            background-color: $riseup_green;
          }
          &.dark-green {
            background-color: $riseup_dark_green;
          }
        }
      }
      .image {
        width: 100%;
      }
    }

    .page-content {
      @include responsive-padding;
      margin-top: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .description {
        color: $riseup_black;
        .title {
          font-size: 22px;
          line-height: 1.2;
          width: 100%;
          margin-bottom: 12px;
          font-weight: bold;
        }

        .subtitle {
          font-size: 18px;
          line-height: 1.4;
        }
      }

      .button-container {
        @include responsive-padding-top-bottom;

        .next-question {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }

</style>
