<template>
  <div class="value-prop-cashflow-goal">
    <div class="player-wrapper">
      <lottie-player class="player"
          :class="[valueProp.backgroundColor]"
          :src="`/animations/${valueProp.animation}`"
          :preserveAspectRatio="'none'"
          :key="valueProp.name"
          :autoplay="true"
          loop/>
    </div>
    <div class="page-content">
      <div class="description">
        <div class="title">{{ valueProp.title }} {{ jobToBeDone }}</div>
        <div class="subtitle">{{ valueProp.subtitle }}</div>
      </div>
      <div class="button-container">
        <riseup-button class="next-question" :title="'הבנתי, מצוין'" :action="nextClicked" :size="'small'" />
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapState } from 'vuex';
import valueProps from '../consts/value-props';
import questions from '../consts/questions';
import Segment from '../../../../../../Segment';

export default {
  name: 'ValuePropCashflowGoal',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot(`ValueProp-${this.valueProp.name}-Entered`, { valuePropIndex: this.valuePropIndex, valuePropName: this.valueProp.name });
    Segment.trackUserGot(`ValueProp_${this.valueProp.name}_Entered_Marketing`);
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('leadQualification', ['answers']),
    ...mapGetters('leadQualification', ['questions', 'jobToBeDone']),
    valueProp() {
      return _.find(valueProps.VALUE_PROPS, { name: valueProps.VALUE_PROP_NAMES.CASHFLOW_GOAL });
    },
    valuePropIndex() {
      return _.findIndex(valueProps.VALUE_PROPS, { name: valueProps.VALUE_PROP_NAMES.CASHFLOW_GOAL });
    },
    answer() {
      return this.answers[questions.QUESTION_NAMES.JOB_TO_BE_DONE];
    },
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction(`ValueProp_${this.valueProp.name}_NextClicked_Marketing`);
      this.next({});
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';

  .value-prop-cashflow-goal {
    @include white-page-frame;
    height: 100%;

    .player-wrapper {
      width: 100%;

      .player {
        &.light-blue {
          background-color: $riseup_light_blue;
        }
        &.light-green {
          background-color: $riseup_light_green;
        }
        &.dark-green {
          background-color: $riseup_dark_green;
        }
        &.white {
          background-color: $riseup_white;
        }
      }
    }

    .page-content {
      @include responsive-padding;
      margin-top: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .description {
        color: $riseup_black;
        .title {
          font-size: 22px;
          line-height: 1.2;
          width: 100%;
          margin-bottom: 12px;
          font-weight: bold,
        }

        .subtitle {
          font-size: 18px;
          line-height: 1.4;
        }
      }

      .button-container {
        @include responsive-padding-top-bottom;

        .next-question {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }

</style>
